/* roboto-condensed-regular - latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('./fonts/roboto-condensed-v25-latin-regular.woff2') format('woff2'),
        url('./fonts/roboto-condensed-v25-latin-regular.woff') format('woff');
}

/* roboto-condensed-italic - latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 400;
    src: local(''),
        url('./fonts/roboto-condensed-v25-latin-italic.woff2') format('woff2'),
        url('./fonts/roboto-condensed-v25-latin-italic.woff') format('woff');
}

/* roboto-condensed-700 - latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    src: local(''),
        url('./fonts/roboto-condensed-v25-latin-700.woff2') format('woff2'),
        url('./fonts/roboto-condensed-v25-latin-700.woff') format('woff');
}

/* roboto-condensed-700italic - latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 700;
    src: local(''),
        url('./fonts/roboto-condensed-v25-latin-700italic.woff2') format('woff2'),
        url('./fonts/roboto-condensed-v25-latin-700italic.woff') format('woff');
}

/* roboto-slab-regular - latin */
@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('./fonts/roboto-slab-v23-latin-regular.woff2') format('woff2'),
        url('./fonts/roboto-slab-v23-latin-regular.woff') format('woff');
}

/* roboto-slab-700 - latin */
@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 700;
    src: local(''),
        url('./fonts/roboto-slab-v23-latin-700.woff2') format('woff2'),
        url('./fonts/roboto-slab-v23-latin-700.woff') format('woff');
}